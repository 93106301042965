import Modal from "@/Components/Modal";
import { Button } from "@/Components/ui/button";
import { Card, CardContent } from "@/Components/ui/card";
import { useProductCategories } from "@/Hooks/CategoriesHook";
import { JSONGetRequest } from "@/Hooks/JSONRequest";
import { cn } from "@/lib/utils";
import Empty from "@/Modules/Empty";
import Loader from "@/Modules/Loader";
import { Icon } from "@iconify/react";
import { useDebounceFn } from "ahooks";
import { useEffect, useState } from "react";
import { Img } from "react-image";

export default function StartDesignModal({ title = "What are you looking for?", show, onClose, onSelect }) {

    const [products, setProducts] = useState([]);
    const [mode, setMode] = useState("category");
    const [category, setCategory] = useState(null);
    const [loading, setLoading] = useState(false);
    const { categories } = useProductCategories();

    const { run } = useDebounceFn((category) => {
        JSONGetRequest({
            href: route("products.paginate", {
                per_page: 20,
                category_id: category?.id
            }),
            onResult: (val) => {
                setCategory(category);
                setProducts(val.data);
                setMode("product");
            },
            onLoading: (val) => setLoading(val),
        });
    }, { wait: 500 });

    useEffect(() => {
        return () => {
            setProducts([]);
            setMode("category");
            setLoading(false);
        }
    }, [show])

    return <Modal title={title} headExtra={
        <Button onClick={() => setMode("category")} size="xs" className={cn("max-w-7xl mr-6", mode === 'category' || products.length === 0 ? "hidden" : "")}>
            <Icon icon="solar:double-alt-arrow-left-broken" width="30" height="30" /> Back
        </Button>
    } className="max-w-5xl min-h-[40vh]" show={show} onClose={onClose}>
        {!loading && mode === "category" && <div className="w-full flex flex-row justify-center items-center flex-wrap gap-4 p-4">
            {categories.map(cat => (
                <Card key={cat.id} onClick={() => run(cat)} className="md:basis-1/5 basis-1/3 cursor-pointer overflow-hidden shadow-xl group">
                    <CardContent>
                        <Img src={cat.image_url} className="group-hover:scale-105 duration-300" />
                    </CardContent>
                    <div className="p-2 bg-primary text-white font-semibold text-xs flex justify-center items-center">
                        {cat.name}
                    </div>
                </Card>
            ))}
        </div>}

        {!loading && mode === "product" && <div className="w-full flex flex-row flex-wrap justify-center items-center  gap-4 p-4">
            {products.map(product => (
                <Card key={product.id} onClick={() => onSelect(product)} className="md:basis-1/5 basis-1/3 cursor-pointer group overflow-hidden shadow-xl col-span-1">
                    <CardContent>
                        <Img src={product.url_thumb} className="group-hover:scale-105 h-[200px] aspect-auto duration-300" />
                        <div className="p-2 bg-primary group-hover:underline text-white font-semibold text-xs flex justify-center items-center">
                            {product.title}
                        </div>
                    </CardContent>
                </Card>
            ))}
            {products.length === 0 && <Empty className="h-[48vh] w-full" text={`No products found for ${category?.name}`} >
                <Button onClick={() => setMode("category")} size="sm">
                    <Icon icon="solar:double-alt-arrow-left-broken" width="30" height="30" />
                    Back</Button>
            </Empty>}
        </div>}
        {loading && <Loader className="h-[48vh] w-full" />}
    </Modal>
}
